<template>
    <section>
    </section>
</template>

<script>
export default {
    name:"PathFinder",
    setup() {
        
    },
    mounted(){
        localStorage.removeItem("myItems");
        this.$router.push("/");
    }
}
</script>