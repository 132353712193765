<template>
  <section class="payment__histroy__main__wrapper" lang="html">
    <div class="breadcrumb__main__wrapper mt-4">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboardOption' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Fee Balance
          </li>
        </ol>
      </nav>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="fee__card__main__wrapper">
          <div class="due__date__wrapper mt-4">
            <h4 class="mb-0">
              Due Date:<span>{{ myData.StringDueDate }}</span>
            </h4>
          </div>
          <div class="fee__card__list__wrapper mt-4">
            <ul>
              <li
                v-for="(fee, index) in myData.cMS_Mobile_FeeCardDetail"
                :key="index"
              >
                <div class="row">
                  <div class="col-6">
                    <div class="fee__card__title">
                      <h4 class="mb-0">{{ fee.TFeeHeadName }}</h4>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="fee__card__value">
                      <p class="mb-0">{{ fee.TFeeHeadActualAmount }}</p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="row">
              <div class="col-6">
                <div class="fee__card__title">
                  <h4 class="mb-0 total__title">Total</h4>
                </div>
              </div>
              <div class="col-6">
                <div class="fee__card__value">
                  <p class="mb-0 totle__value">{{ total }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "feeBalance",
  data() {
    return {
      myData: [],
      total: 0,
    };
  },
  mounted() {
    this.getResult();
  },

  methods: {
    async getResult() {
      const token = localStorage.getItem("token");
      const studentid = this.$route.params.id;
      try {
        var model = {
          UserIdentity: token,
          StudentId: studentid,
        };
        const response = await axios.post(
          `https://apiesm.cyberasol.com/api/Mobile/MobileStudentFeeCard`,
          model
        );
        this.myData = response.data;
        this.totalFees();
      } catch (error) {
        console.error(error);
      }
    },
    totalFees() {
      const fees = this.myData.cMS_Mobile_FeeCardDetail;
      for (let i = 0; i < fees.length; i++) {
        this.total += fees[i].TFeeHeadActualAmount;
      }
    },
  },
};
</script>
