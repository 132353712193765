<template>
  <section class="payment__histroy__main__wrapper" lang="html">
    <div class="breadcrumb__main__wrapper mt-4">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboardOption' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Daily Diary
          </li>
        </ol>
      </nav>
    </div>
    <div class="row mt-4">
      <div class="col-lg-12">
        <form @submit.prevent="getResult">
          <div class="row">
            <div class="col-lg-8">
              <div class="input-wrapper mb-3">
                <div class="form-floating">
                  <input
                    type="date"
                    class="form-control"
                    id="DiaryDate"
                    v-model="DiaryDate"
                    placeholder="Date"
                    :class="{ error: !isDiaryDateValid }"
                    @blur="validateDiaryDate"
                  />
                  <label for="floatingInput" class="dark___color"
                    >SELECT DATE</label
                  >
                  <div v-if="!isDiaryDateValid">
                    <label class="error-label">Please Select Date</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form__submit__btn">
                <button class="btn btn-primary style__2__btn btn-lg w-100">
                  Show Diary
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div
      class="row justify-content-center mt-4"
      v-if="!noDataFound && !apiPreloader"
    >
      <div class="col-lg-12">
        <div class="dailyDiary___content__wrapper">
          <div class="dailyDiary___content__row">
            <div class="row">
              <div
                class="col-lg-6 col-md-12 col-12 mb-4"
                v-for="(item, index) in myData"
                :key="index"
              >
                <div class="single__dailyDiary__content_wrapper">
                  <div class="single__dailyDiary__header">
                    <div class="row">
                      <div class="col-6 col-lg-7 col-md-7">
                        <div class="single__dailyDiary__subject">
                          <div class="single__dailyDiary_first_letter">
                            <span class="hiden__letter">{{
                              item.SubjectName.charAt(0)
                            }}</span>
                          </div>
                          <div class="single__dailyDiary__subject__info ms-2">
                            <h4 class="mb-0">{{ item.SubjectName }}</h4>
                            <label>{{ item.CreatedBy.trim() }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 col-lg-5 col-md-5">
                        <div class="single__dailyDiary__image__download">
                          <a
                            v-if="item.LogoContent != null"
                            :href="`data:image/jpeg;base64,${item.LogoContent}`"
                            :download="`${item.UserFileName}`"
                            >Download</a
                          >
                          <button
                            v-if="item.LogoContent == null"
                            @click="noImage"
                          >
                            Download
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="single__dailyDiary__body mt-3">
                    <div class="row">
                      <div class="col-12 col-lg-7 col-md-7">
                        <div class="single__dailyDiary__desc">
                          <p class="mb-0">{{ item.Text }}</p>
                        </div>
                      </div>
                      <div class="col-12 col-lg-5 col-md-5">
                        <div class="active__dailyDiary__list">
                          <ul>
                            <li
                              v-for="(day, index) in daysOfWeek"
                              :key="index"
                              :class="{ active: isActive(day.name) }"
                            >
                              <label>{{ day.name.charAt(0) }}</label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="noDataFound">
      <div class="no__data__information__wrapper text-center mb-5">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-5">
            <img
              src="../../assets/empty_search.svg"
              width="500"
              alt="emptySearch"
              class="img-fluid"
            />
          </div>
          <div class="col-lg-5">
            <div class="no__data_wrapper">
              <h2 class="mb-0">
                oops..!<br />
                No Data Found
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div id="loader-wrapper" v-if="apiPreloader">
      <div id="loader"></div>
      <div class="loader-section section-left"></div>
    </div> -->
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "Diary",
  data() {
    return {
      myData: [],
      DiaryDate: "",
      isDiaryDateValid: true,
      noDataFound: false,
      // apiPreloader: false,
      daysOfWeek: [
        { name: "Monday" },
        { name: "Tuesday" },
        { name: "Wednesday" },
        { name: "Thursday" },
        { name: "Friday" },
        { name: "Saturday" },
      ],
    };
  },
  methods: {
    validateDiaryDate() {
      this.isDiaryDateValid = !!this.DiaryDate;
    },
    async getResult() {
      this.validateDiaryDate();
      if (!this.DiaryDate) {
        return;
      } else {
        const token = localStorage.getItem("token");
        const studentid = this.$route.params.id;
        try {
          var model = {
            DiaryDate: this.DiaryDate,
            UserIdentity: token,
            StudentId: studentid,
          };
          const response = await axios.post(
            `https://apiesm.cyberasol.com/api/Mobile/MobileGetStudentDiary`,
            model
          );
          this.myData = response.data.DiaryList;
          // this.apiPreloader = true;
          this.noDataFound = false;
          if (this.myData.length > 0) {
            // this.apiPreloader = false;
          }
          if (this.myData.length == 0) {
            this.noDataFound = true;
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    isActive(dayOfWeek) {
      for (let i = 0; i < this.myData.length; i++) {
        const diaryItem = this.myData[i];
        const date = new Date(diaryItem.DateFromString);
        const dayOfWeekOfDiaryItem = date.toLocaleDateString("en-US", {
          weekday: "long",
        });
        if (dayOfWeek === dayOfWeekOfDiaryItem) {
          return true;
        }
      }
      return false;
    },
    noImage() {
      this.$swal.fire({
        icon: "info",
        title: "No Image",
        text: "This Daily Diary Has No Image Content",
        showCancelButton: true,
      });
    },
  },
};
</script>
