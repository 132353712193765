import { createApp , Vue } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'

// // Register an event listener on the window object's beforeunload event
// window.addEventListener('beforeunload', function (event) {
//     // Clear the localStorage
//     localStorage.clear();
//   });

createApp(App).use(store).use(VueSweetalert2).use(router).mount("#app");
