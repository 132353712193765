<template>
  <div class="options__content__wrapper mt-4 mb-5">
    <div class="row">
      <div class="col-lg-12">
        <div class="goback__student_list text-start mb-3">
          <button @click="backtoStudentList" class="go__back__img">
            <img src="../assets/backArrow.png" alt="Back-Arrow">
          </button>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 mb-4">
            <div class="single__option__wrapper">
              <router-link
                :to="{ name: 'paymentHistory' }"
                class="d-block text-center single__option_link">
                <div class="single__option__inner_wrapper">
                  <img
                    src="../assets/dashboard-icons/history.png"
                    alt="histroy"
                    class="img-fluid"
                  />
                  <h3>Payment History</h3>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 mb-4">
            <div class="single__option__wrapper">
              <router-link
                :to="{ name: 'attendanceHistory' }"
                class="d-block text-center single__option_link">
                <div class="single__option__inner_wrapper">
                  <img
                    src="../assets/dashboard-icons/authentication.png"
                    alt="histroy"
                    class="img-fluid"
                  />
                  <h3>Attendance</h3>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 mb-4">
            <div class="single__option__wrapper">
              <router-link
                :to="{ name: 'results' }"
                class="d-block text-center single__option_link">
                <div class="single__option__inner_wrapper">
                  <img
                    src="../assets/dashboard-icons/evaluation.png"
                    alt="histroy"
                    class="img-fluid"
                  />
                  <h3>Results</h3>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 mb-4">
            <div class="single__option__wrapper">
              <router-link
                :to="{ name: 'smsAlert' }"
                class="d-block text-center single__option_link">
                <div class="single__option__inner_wrapper">
                  <img
                    src="../assets/dashboard-icons/report.png"
                    alt="histroy"
                    class="img-fluid"
                  />
                  <h3>Alert & SMS</h3>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 mb-4">
            <div class="single__option__wrapper">
              <router-link
                :to="{ name: 'notification' }"
                class="d-block text-center single__option_link">
                <div class="single__option__inner_wrapper">
                  <img
                    src="../assets/dashboard-icons/bell.png"
                    alt="Notifications"
                    class="img-fluid"
                  />
                  <h3>Notifications</h3>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 mb-4">
            <div class="single__option__wrapper">
              <router-link
                :to="{ name: 'FeeBalance' }"
                class="d-block text-center single__option_link">
                <div class="single__option__inner_wrapper">
                  <img
                    src="../assets/dashboard-icons/money-report.png"
                    alt="histroy"
                    class="img-fluid"
                  />
                  <h3>Fee Balance</h3>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 mb-4">
            <div class="single__option__wrapper">
              <router-link
                :to="{ name: 'EventCalendar' }"
                class="d-block text-center single__option_link">
                <div class="single__option__inner_wrapper">
                  <img
                    src="../assets/dashboard-icons/schedule.png"
                    alt="histroy"
                    class="img-fluid"
                  />
                  <h3>Event & Calendar</h3>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 mb-4">
            <div class="single__option__wrapper">
              <router-link
                :to="{ name: 'complaints' }"
                class="d-block text-center single__option_link">
                <div class="single__option__inner_wrapper">
                  <img
                    src="../assets/dashboard-icons/complaint.png"
                    alt="histroy"
                    class="img-fluid"
                  />
                  <h3>Complaint</h3>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 mb-4">
            <div class="single__option__wrapper">
              <router-link
                :to="{ name: 'diary' }"
                class="d-block text-center single__option_link">
                <div class="single__option__inner_wrapper">
                  <img
                    src="../assets/dashboard-icons/diary.png"
                    alt="histroy"
                    class="img-fluid"
                  />
                  <h3>Diary</h3>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "dasboardOption",
  setup() {},
  methods:{
    backtoStudentList(){
      localStorage.removeItem("myItems");
      this.$router.push({ name: "students"});
    }
  }
};
</script>

<style>
.custom__btn.custom__padding{
  padding: 10px 30px;
}
.go__back__img{
  background: transparent;
  border: none;
}
.go__back__img img{
  width: 60px;
}
.go__back__img{
  position: relative;
}
</style>
