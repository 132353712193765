<template>
  <section class="payment__histroy__main__wrapper" lang="html">
    <div class="breadcrumb__main__wrapper mt-4">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'complaints' }">Complaints</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Chat</li>
        </ol>
      </nav>
    </div>
    <div class="row mt-4 mb-4">
      <div class="col-lg-12">
        <div class="chat-box">
          <div class="messages" v-if="!emptyMessage" ref="chatbox">
            <div v-for="message in messages" :key="message.Id">
              <p
                class="chat__message"
                :class="
                  message.ComplaintResponseType === 'Guardian'
                    ? 'right'
                    : 'left'
                "
              >
                <span>{{ message.Response }}</span>
              </p>
            </div>
          </div>
          <div class="emptymessage" v-if="emptyMessage">
            <div>
              <h4>No Chat Data Available</h4>
            </div>
          </div>
          <div class="chat__input__wrapper">
            <form @submit.prevent="sendMessage">
              <input
                type="text"
                v-model="newMessage"
                required
                placeholder="Enter Some Thing"
              />
              <button type="submit" class="d-block">
                <img src="../../assets/paper.png" alt="send" />
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "ChatView",
  data() {
    return {
      messages: [],
      newMessage: "",
      emptyMessage: true,
    };
  },
  mounted() {
    this.fetchMessages();
    if (this.emptyMessage == false) {
      const chatbox = this.$refs.chatbox;
      chatbox.scrollTop = chatbox.scrollHeight;
    }
  },
  watch: {
    messages: function () {
      this.$nextTick(function () {
        if (this.emptyMessage == false) {
          const chatbox = this.$refs.chatbox;
          chatbox.scrollTop = chatbox.scrollHeight;
        }
      });
    },
  },
  methods: {
    fetchMessages() {
      const token = localStorage.getItem("token");
      const chatId = this.$route.params.chatid;
      var model = {
        ComplaintIdFk: chatId,
        UserIdentity: token,
      };
      axios
        .post(
          "https://apiesm.cyberasol.com/api/Mobile/ComplaintResponseList",
          model
        )
        .then((response) => {
          if (response.data.CMS_Mobile_Complaints_Response.length > 0) {
            this.messages =
              response.data.CMS_Mobile_Complaints_Response.slice().reverse();
            this.emptyMessage = false;
          } else {
            this.emptyMessage = true;
            this.messages = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendMessage() {
      const token = localStorage.getItem("token");
      const chatId = this.$route.params.chatid;
      const model = {
        ComplaintIdFk: chatId,
        ComplaintResponseType: "Guardian",
        Response: this.newMessage,
        UserIdentity: token,
      };
      axios
        .post(
          "https://apiesm.cyberasol.com/api/Mobile/AddComplaintResponse",
          model
        )
        .then((response) => {
          this.messages.push(model);
          this.fetchMessages();
          this.newMessage = "";
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.chat-box {
  position: relative;
}
.chat__input__wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.messages {
  border: 1px dotted rgba(0, 0, 0, 0.2);
  height: 500px;
  max-height: 500px;
  overflow-y: auto;
  border-radius: 10px;
  padding: 20px 20px 65px 20px;
}
.emptymessage {
  border: 1px dotted rgba(0, 0, 0, 0.2);
  height: 500px;
  max-height: 500px;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 20px 20px 65px 20px;
}
.emptymessage h4 {
  text-transform: uppercase;
  font-weight: 400;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.chat__message span {
  display: inline-block;
  transform-origin: 0 100%;
  padding-top: 0;
  transform: scale(0);
  max-height: 0;
  overflow: hidden;
  animation: message 0.4s ease-out 0s forwards;
  /*animation-delay: 1;*/
}
.chat__message span {
  padding: 10px !important;
  border-radius: 12px 12px 12px 0px;
}
.chat__message.right span {
  background: #d2ecd4;
  border-radius: 12px 12px 0px 12px;
  transform-origin: 100% 100%;
}
.chat__message.left span {
  background: rgba(15, 117, 187, 0.2);
  border-radius: 12px 12px 12px 0px;
}
.messages::-webkit-scrollbar {
  width: 10px;
}
.messages::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.messages::-webkit-scrollbar-thumb {
  background: rgba(210, 236, 212, 0.9);
  border-radius: 10px;
}
.messages::-webkit-scrollbar-thumb:hover {
  background: rgba(210, 236, 212, 1);
}
.calendar-header h4{
  text-transform: uppercase;
}
@keyframes message {
  0% {
    max-height: 100vmax;
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    max-height: 100vmax;
    overflow: visible;
    padding-top: 1rem;
  }
}
.chat__input__wrapper input {
  width: 100%;
  padding: 15px;
  background: #f1f1f1;
  border: none;
  border: none;
  border-radius: 0px 0px 10px 10px;
  transition: all 0.3s ease-in-out;
}
.chat__input__wrapper input:focus {
  outline: none;
}
.chat__input__wrapper form {
  position: relative;
}
.chat__input__wrapper img {
  width: 40px;
}
.chat__input__wrapper button {
  border: none;
  background: none;
  position: absolute;
  right: 30px;
  top: 8px;
}
</style>
