<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
</template>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Rubik", sans-serif !important;
}
.login__bg__wrapper {
  background-image: url("assets/login-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.login__header__logo img {
  width: 220px;
}
.sign__up__redirect {
  text-align: center;
}
.form-floating > label {
  color: #0f75bb;
  font-weight: 600;
}
.form-control {
  font-weight: 500 !important;
}
.form-control:focus {
  box-shadow: none !important;
}
.info__label {
  color: #fff;
  margin-bottom: 4px;
}
.form__submit__btn button {
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  font-size: 19px;
}
.form__submit__btn .style__2__btn {
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  padding: 20px 18px 16px 18px;
  line-height: 18px;
  background: #0f75bb;
}
.dark___color {
  color: #0f75bb !important;
  opacity: 1 !important;
  text-transform: capitalize;
}
.form__submit__btn button:hover {
  background: #f3ae1a;
  border-color: #f3ae1a;
}
.sign__up__redirect p {
  color: #fff;
}
.sign__up__redirect p a {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  color: #f3ae1a;
}
.sign__up__redirect p a:hover {
  color: #f3ae1a;
}
.powered__by {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
}
.content__powered_by h3 {
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
}
.img__powered_by img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.tag__line {
  text-align: center;
  color: #fff;
  text-transform: capitalize;
}
.mini__header__wrapper {
  background: #0f75bb;
  padding: 15px 0px;
}
.welcome__note h3 {
  text-transform: uppercase;
  font-size: 26px;
  font-weight: 500;
  color: #fff;
  letter-spacing: 0.03em;
}
.custom__btn {
  background: #f3ae1a;
  border: none;
  padding: 5px 20px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
}
a.custom__btn {
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  display: block;
  border-radius: 5px;
  background: #003e78;
  transition: all 0.3s ease-in-out;
}
a.custom__btn:hover {
  background: #f3ae1a;
  color: #fff;
}
button.custom__btn {
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  display: block;
  border: none;
  border-radius: 5px;
  background: #003e78;
  transition: all 0.3s ease-in-out;
}
button.custom__btn:hover {
  background: #f3ae1a;
  color: #fff;
}
button.custom__btn:focus {
  outline: none;
  box-shadow: none;
}
.single__profile__detail_btn {
  margin-top: 8px;
}
.single__content__wrapper {
  background: #0f75bb;
  padding: 14px;
  border-radius: 12px;
}
ul {
  list-style: none;
  padding-left: 0px !important;
  margin-bottom: 0px !important;
}
.inner__content__wrapper {
  display: flex;
  align-items: center;
  background: green;
}
.inner__title__label label {
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  text-transform: capitalize;
}
.inner__title__desc label {
  color: #fff;
  font-size: 17px;
  font-weight: 400;
}
.profile__content__list ul li {
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 1px solid rgba(243, 174, 26, 0.9);
}
.profile__content__list ul li:first-child {
  padding-top: 0px;
}
.profile__content__list ul li:last-child {
  padding-bottom: 0px;
  border-bottom: none;
}
.profile__avatar img {
  border-radius: 10px;
  height: 213px;
  object-fit: cover;
  width: 100%;
}
.mini__footer__wrapper {
  background: rgba(0, 0, 0, 0.05);
  padding: 20px 0px 17px 0px;
}
.mini__footer__wrapper ul li a img {
  width: 30px;
  height: 30px;
}
.mini__footer__wrapper ul li {
  display: inline-block;
  margin-right: 15px;
}
.mini__footer__wrapper ul li:last-child {
  margin-right: 0px;
}
.single__option_link {
  border: 1px solid #eee;
  padding: 25px;
  border-radius: 15px;
  text-decoration: none;
  transition: all 0.4s;
}
.single__option_link:hover {
  box-shadow: 0 20px 50px rgb(15 117 187 / 7%);
  border-color: transparent;
  background: #0f75bb;
}
.single__option__inner_wrapper img {
  width: 80px;
  transition: all 0.3s ease-in-out;
}
.single__option_link:hover .single__option__inner_wrapper img {
  transform: rotate(5deg);
}
.single__option__inner_wrapper h3 {
  margin-top: 20px;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 22px;
  text-transform: uppercase;
  color: #000;
  transition: all 0.4s;
}
.single__option_link:hover .single__option__inner_wrapper h3 {
  color: #fff;
}
.navbar-brand {
  font-weight: 500;
  text-transform: uppercase;
  color: #fff !important;
}
.nav-img img {
  width: 24px;
  object-fit: contain;
}
.nav-link {
  color: #000 !important;
  text-transform: uppercase;
  font-size: 18px !important;
}
.nav-link:hover {
  color: #f3ae1a !important;
}
.navbar.bg-light {
  background: #0f75bb !important;
}
.navbar-toggler {
  border: none !important;
}
.navbar-toggler:focus {
  box-shadow: none !important;
}
.navbar-toggler-icon {
  background-image: url("assets/download.svg") !important;
}
.menu__lable {
  position: relative;
  top: 2px;
  color: #fff;
}
.avatar__profile {
  width: 34px;
  height: 34px !important;
  object-fit: cover;
  border-radius: 5px;
}
.dropdown-toggle {
  padding: 0px !important;
  background: none !important;
  border: none !important;
}
.dropdown-toggle::after {
  content: none !important;
}
.dropdown-menu[data-bs-popper] {
  left: unset !important;
  right: 0 !important;
}
.profile__info__wrapper {
  padding: 0px 16px;
}
.profile__info__wrapper h6 {
  font-weight: 500;
  font-size: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: uppercase;
}
.profile__info__wrapper p {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.8);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.profile__placeholder__wrapper {
  background: rgba(0, 0, 0, 0.03);
  padding: 10px 0px;
}
.profile__img_wrapper img {
  width: 50px;
  height: 50px;
  padding: 2px;
  border-radius: 10px;
  object-fit: cover;
  border: 2px solid #f4af1a;
}
.single__profile__information {
  display: flex;
  align-items: center;
}
.profile__img__content h6 {
  text-transform: uppercase;
  font-size: 18px;
}
.profile__school {
  position: relative;
  top: -5px;
}
.breadcrumb a {
  text-decoration: none;
  color: #0f75bb;
}
.navbar-nav li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.navbar-nav li:last-child {
  border-bottom: none;
}
table.dataTable thead th,
table.dataTable thead td,
table.dataTable tfoot th,
table.dataTable tfoot td {
  background: #0f75bb;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}
div.dataTables_wrapper div.dataTables_length label {
  text-transform: uppercase;
}
div.dataTables_wrapper div.dataTables_filter label {
  text-transform: uppercase;
}
.page-link {
  border: none !important;
  color: #0f75bb !important;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  padding: 10px 0px !important;
  margin: 0px;
}
.page-link:focus {
  box-shadow: none !important;
}
.page-link.active,
.active > .page-link {
  color: #fff !important;
  background-color: #0f75bb !important;
}
.error-label {
  color: red;
}
.swal2-icon.swal2-error {
  border-color: rgba(255, 0, 0, 0.7) !important;
  color: rgba(255, 0, 0, 0.7) !important;
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  background-color: rgba(255, 0, 0, 0.7) !important;
}
.swal2-title {
  color: rgba(255, 0, 0, 0.7) !important;
  padding: 10px 1em 0 !important;
  font-size: 1.375em !important;
  text-transform: uppercase !important;
}
.swal2-html-container {
  margin: 0.2em 1.6em 0.3em !important;
}
.swal2-styled.swal2-cancel {
  background-color: rgba(255, 0, 0, 0.7) !important;
}
.swal2-styled.swal2-cancel:focus {
  outline: none !important;
  box-shadow: none !important;
}
.swal2-styled.swal2-confirm {
  background-color: #0f75bc !important;
  padding-left: 2em !important;
  padding-right: 2em !important;
}
.swal2-styled.swal2-confirm:focus {
  outline: none !important;
  box-shadow: none !important;
}
/* Loader - Css */
.placeholder-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  text-align: center;
}
.loader-content-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}
.wrapper {
  position: absolute;
  top: -35px;
  transform: scale(1.5);
}
.loader {
  height: 25px;
  width: 1px;
  position: absolute;
  animation: rotate 3.5s linear infinite;
}
.loader .dot {
  top: 30px;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  position: relative;
}
.loader .dot.blueColor {
  background: #0f75bc;
}
.loader .dot.yellowColor {
  background: #f4af1a;
}
.text {
  position: absolute;
  bottom: -85px;
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #0f75bc;
}
.text span {
  color: #f4af1a;
}
@keyframes rotate {
  30% {
    transform: rotate(220deg);
  }
  40% {
    transform: rotate(450deg);
    opacity: 1;
  }
  75% {
    transform: rotate(720deg);
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: rotate(0deg);
  }
}
.loader:nth-child(1) {
  animation-delay: 0.15s;
}
.loader:nth-child(2) {
  animation-delay: 0.3s;
}
.loader:nth-child(3) {
  animation-delay: 0.45s;
}
.loader:nth-child(4) {
  animation-delay: 0.6s;
}
.loader:nth-child(5) {
  animation-delay: 0.75s;
}
.loader:nth-child(6) {
  animation-delay: 0.9s;
}
.loding__gif {
  width: 16px;
  height: 16px;
}
.due__date__wrapper {
  background: #0f75bb;
  padding: 10px;
}
.due__date__wrapper h4 {
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 400;
}
.due__date__wrapper h4 span {
  margin-left: 10px;
}
.fee__card__list__wrapper ul li:nth-child(odd) {
  background: #f2f2f2;
}
.fee__card__title,
.fee__card__value {
  padding: 10px 5px 10px 10px;
}
.fee__card__title h4 {
  font-weight: 400;
  font-size: 19px;
  text-transform: capitalize;
}
.total__title,
.totle__value {
  font-weight: 600 !important;
  text-transform: uppercase !important;
}
.totle__value {
  font-size: 19px !important;
}
.accordion-item {
  border: none !important;
}
.accordion-button {
  background-color: #0f75bb !important;
  color: #fff !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.custom__result__table thead tr {
  background: #0f75bb;
  color: #fff;
}
.custom__result__table thead tr th {
  text-transform: uppercase;
  font-weight: 400;
}
.custom__result__table tbody tr td {
  text-transform: capitalize;
}
.custom__result__table tbody tr.total__result {
  color: #0f75bb;
}
.custom__result__table tbody tr.total__result td {
  font-weight: 600;
  text-transform: uppercase;
}
.accordion-button:not(.collapsed)::after {
  filter: invert(1);
}
.accordion-button::after {
  filter: invert(1);
}
.accordion-button {
  font-size: 18px !important;
  font-weight: 400;
  text-transform: uppercase;
}
.no__data__information__wrapper h2 {
  text-transform: uppercase;
  font-size: 46px;
}
.single__dailyDiary__content_wrapper {
  border: 2px solid #0f75bb;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  height: 100%;
}
.single__dailyDiary__subject {
  display: flex;
  align-items: center;
}
.single__dailyDiary_first_letter span {
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  background: #0f75bb;
  color: #fff;
  font-size: 30px;
  border-radius: 5px;
  font-weight: 500;
  text-transform: uppercase;
}
.single__dailyDiary__subject__info {
  position: relative;
  top: 3px;
}
.single__dailyDiary__subject__info h4 {
  font-size: 20px;
  line-height: 22px;
  color: #0f75bb;
  font-weight: 600;
  text-transform: capitalize;
}
.single__dailyDiary__subject__info label {
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  position: relative;
  top: -3px;
}
.single__dailyDiary__image__download a {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  background: #0f75bb;
  padding: 10px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
}
.single__dailyDiary__image__download a:hover {
  background: #f3ae1a;
}
.single__dailyDiary__image__download button {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  height: 48px;
  background: #0f75bb;
  padding: 10px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
}
.single__dailyDiary__image__download button:hover {
  background: #f3ae1a;
  color: #0f75bb;
}
.single__dailyDiary__image__download button:focus {
  outline: none;
}
.active__dailyDiary__list ul li {
  display: inline-block;
}
.active__dailyDiary__list ul li label {
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
}
.active__dailyDiary__list ul li.active {
  background: #0f75bb;
  border-radius: 5px;
  color: #fff;
}
.single__dailyDiary__desc p {
  word-break: break-all;
}
.payment__histroy__main__wrapper {
  position: relative;
}

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow: hidden;
}
#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #16a085;
  -webkit-animation: spin 1.7s linear infinite;
  animation: spin 1.7s linear infinite;
  z-index: 11;
}
#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e74c3c;
  -webkit-animation: spin-reverse 0.6s linear infinite;
  animation: spin-reverse 0.6s linear infinite;
}
#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #f9c922;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes spin-reverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
}
#loader-wrapper .loader-section.section-left {
  left: 0;
}
.single__notification__main__wrapper {
  border-radius: 5px;
  padding: 20px;
  border: 2px solid #0f75bb;
}
.notification__content__wrapper h3 {
  font-weight: 400;
  font-size: 22px;
  text-transform: uppercase;
}
.notification__content__wrapper p {
  color: rgba(0, 0, 0, 0.6);
  word-break: break-all;
}
.notification__media__wrapper label {
  text-transform: uppercase;
  color: #0f75bb;
  font-weight: 400;
  font-size: 22px;
}
.notification__media__wrapper a {
  background: #0f75bb;
  color: #fff;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  width: 100%;
  text-transform: capitalize;
  padding: 7px;
  font-weight: 500;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.notification__media__wrapper a:hover {
  background: #f3ae1a;
}
.no__data_wrapper {
  text-align: center;
}
.no__data_wrapper h2 {
  text-transform: uppercase;
}
.no__option_wrapper h4{
  text-transform: uppercase;
  font-weight: 400;
  padding-top: 150px;
}
.single__complaint__wrapper{
  border:2px solid #0f75bb;
  padding: 15px;
  border-radius: 5px;
  position: relative;
  height: 100%;
}
.complaint__number label{
  font-size: 30px;
  font-weight: 400;
  color: #0f75bb;
  word-break: break-all;
}
.complaint__content{
  cursor: pointer;
}
.complaint__content label{
  text-transform: uppercase;
  font-size: 18px;
  color: #0f75bb;
  font-weight: 400;
  cursor: pointer;
}
.complaint__content p{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.complaint__chat{
  text-align: center;
}
.complaint__inner__content img{
  width: 50px;
}
.complaint__chat a{
  text-decoration: none;
}
.complaint__chat label{
  text-transform: uppercase;
  font-size: 18px;
  color: #0f75bb;
  font-weight: 400;
  cursor: pointer;
}
@media (min-width: 320px) and (max-width: 767px) {
  .profile__content__list ul li:first-child {
    padding-top: 5px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .profile__content__list ul li:first-child {
    padding-top: 5px;
  }
}
</style>
