<template>
  <section class="payment__histroy__main__wrapper" lang="html">
    <div class="breadcrumb__main__wrapper mt-4">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboardOption' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Event & Calendar
          </li>
        </ol>
      </nav>
    </div>
    <div class="row justify-content-center mt-3">
      <div class="col-lg-12">
        <div>
          <div class="calendar-header mb-4">
            <h4>{{ month }} {{ year }}</h4>
          </div>
          <div class="row mb-5">
            <div class="col-lg-8 order-1 order-lg-0 order-md-0">
              <div class="calendar-grid">
                <div
                  class="calendar-day"
                  v-for="(day, index) in days"
                  :key="index"
                  :class="{
                    'has-event': day.events.length > 0,
                    today: isToday(day.date),
                  }"
                  @click="showEventDetails(day)"
                >
                  <div class="calendar-day-number">
                    <span>{{ day.date.getDate() }}</span>
                  </div>
                  <div class="day-name">
                    {{
                      day.date.toLocaleDateString("en-US", { weekday: "short" })
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 order-0 order-lg-1 order-md-1">
              <div>
                <div class="event__label mt-md-4 mt-0 mt-lg-0">
                  <h4 class="mb-0">Event Today</h4>
                  <small>click on Calendar Event to view details</small>
                </div>
                <div class="event__details" v-if="selectedDay">
                  <div>{{ selectedDay.date.toDateString() }}</div>
                  <div v-for="event in selectedDay.events" :key="event">
                    {{ event }}
                  </div>
                  <p v-if="selectedDay.events.length == 0">No event on this day.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "eventCalendar",
  data() {
    return {
      calendarList: [
        // {
        //   DayString: "5 March 2023",
        //   Description: "",
        // },
      ],
      selectedDay: null,
      currentDate: new Date(),
    };
  },
  mounted() {
    this.getResult();
  },

  methods: {
    async getResult() {
      const token = localStorage.getItem("token");
      const studentid = this.$route.params.id;
      const year = this.year;
      const month = this.month;
      try {
        var model = {
          UserIdentity: token,
          StudentId: studentid,
          Year: year,
          Month: month,
        };
        const response = await axios.post(
          `https://apiesm.cyberasol.com/api/Mobile/MobileGetStudentCalender`,
          model
        );
        if (response.status === 200) {
          this.calendarList = response.data.CalendarList;
        }
      } catch (error) {
        console.error(error);
      }
    },
    isToday(date) {
      const today = new Date();
      return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      );
    },
    getMonthIndex(month) {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return monthNames.findIndex(
        (name) => name.toLowerCase() === month.toLowerCase()
      );
    },
   
    showEventDetails(day) {
    //   // console.log(day);
    //   // alert(day.events.length);
    //   // if(day.events.length > 0){
    //   //   if(day.events[0] == ""){
    //   //     console.log("Inner:" + day.events[0]);
    //   //     this.selectedDay = nu;
    //   //   }
    //   // }
    //   // else{
    //   //   console.log("length < 0");
    //   // }
      
      this.selectedDay = day;
    },
  },

  computed: {
    month() {
      // Parse the month and year from the first day in the list
      const month = this.currentDate.toLocaleString("default", {
        month: "long",
      });
      // const [day, month, year] = this.calendarList[0].DayString.split(" ");
      return month;
    },
    year() {
      // const [day, month, year] = this.calendarList[0].DayString.split(" ");
      const year = this.currentDate.getFullYear();
      return year;
    },
    days() {
      const date = new Date(this.year, this.getMonthIndex(this.month), 1);
      const daysInMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      ).getDate();
      const days = [];

      for (let i = 1; i <= daysInMonth; i++) {
        const dayDate = new Date(this.year, this.getMonthIndex(this.month), i);
        days.push({
          date: dayDate,
          events: [],
        });
      }

      for (const event of this.calendarList) {
        const [day, month, year] = event.DayString.split(" ");
        const eventDate = new Date(year, this.getMonthIndex(month), day);

        const dayIndex = days.findIndex(
          (day) => day.date.getTime() === eventDate.getTime()
        );
        if (dayIndex >= 0) {
          days[dayIndex].events.push(event.Description);
        }
      }
      return days;
    },
  },
};
</script>

<style>
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.calendar-grid .calendar-day {
  padding: 15px;
  cursor: pointer;
  /* border-radius: 10px; */
  position: relative;
}
.calendar-day.has-event::after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #f4af1a;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 5px;
}
.calendar-day.has-event .calendar-day-number span {
  color: #9c0a32;
}
.calendar-day.has-event .day-name {
  color: #9c0a32;
}
.calendar-grid .calendar-day:nth-child(odd) {
  background: rgba(0, 0, 0, 0.04);
  border-right: 1px dotted rgba(0, 0, 0, 0.2);
  border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
}
.calendar-day-number span {
  font-weight: 600;
  font-size: 20px;
}
.event__label h4 {
  text-transform: uppercase;
}
.event__label {
  margin-bottom: 20px;
}
.event__label small {
  text-transform: capitalize;
}
.calendar-day.today::after {
  background-color: #0f75bb;
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 50% 50%);
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
}
@media (min-width: 320px) and (max-width: 767px) {
  .calendar-grid {
    grid-template-columns: repeat(5, 1fr);
  }
  .event__details {
    margin-bottom: 20px;
  }
  .calendar-grid .calendar-day {
    padding: 5px;
  }
}
</style>
