<template>
  <section class="payment__histroy__main__wrapper">
    <div class="breadcrumb__main__wrapper mt-4">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Payment History
          </li>
        </ol>
      </nav>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="data__table__content__wrapper mt-4 mb-5">
          <div class="table-responsive">
            <table ref="mytable" class="table table-striped w-100">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in myData" :key="index">
                  <td>
                    {{ item.Date }}
                  </td>
                  <td>
                    {{ item.Amount }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import "datatables.net-bs5";
import "datatables.net-bs5/css/dataTables.bootstrap5.css";
import "datatables.net-buttons-bs5";
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css";
import axios from "axios";
import $ from "jquery";
export default {
  name: "paymentHistory",
  data() {
    return {
      myData: [],
    };
  },
  mounted() {
    this.getResult();
  },
  methods: {
    async getResult() {
      const token = localStorage.getItem("token");
      const studentid = this.$route.params.id;
      try {
        var model = {
          UserIdentity: token,
          StudentId: studentid,
        };
        const response = await axios.post(
          `https://apiesm.cyberasol.com/api/Mobile/MobileStudentFeePaymentHistory`,
          model
        );
        this.myData = response.data.FeePaymentHistory;
        this.initTable();
      } catch (error) {
        console.error(error);
      }
    },
    initTable() {
      this.$nextTick(() => {
        $(this.$refs.mytable).DataTable();
      });
    },
  },
  watch: {
    users: {
      handler() {
        // Redraw the table when users changes
        this.$nextTick(() => {
          $(this.$refs.mytable).DataTable().draw();
        });
      },
      deep: true,
    },
  },
  beforeDestroy() {
    // Destroy the table instance when the component is destroyed
    $(this.$refs.mytable).DataTable().destroy(true);
  },
};
</script>
