<template>
  <section class="payment__histroy__main__wrapper" lang="html">
    <div class="breadcrumb__main__wrapper mt-4">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Complaints</li>
        </ol>
      </nav>
    </div>
    <div class="row mt-4">
      <div class="col-lg-12">
        <div class="add__btn text-end mb-4">
          <router-link :to="{name:'addComplaint'}"> Add New Complaint</router-link>
        </div>
      </div>
      <div class="col-lg-6 mb-4" v-for="(item, index) in myData" :key="index">
        <div class="single__complaint__wrapper">
          <div class="row">
            <div class="col-lg-2 pe-lg-0">
              <div class="complaint__number">
                <label>#{{ item.ComplaintId }}</label>
              </div>
            </div>
            <div class="col-lg-8">
              <div
                class="complaint__content"
                data-toggle="offcanvas" @click="mesgDisplay(item.ComplaintTitle,item.ComplaintText)"
              >
                <label>{{ item.ComplaintTitle }}</label>
                <p class="mb-0">{{ item.ComplaintText }}</p>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="complaint__chat">
                <router-link :to="{name:'chat', params: { chatid: item.ComplaintId }}">
                  <div class="complaint__inner__content">
                    <img
                      src="../../assets/chat.png"
                      alt="chat"
                      class="img-fluid"
                    />
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="chat-box">
        <div class="messages">
          <div v-for="message in messages" :key="message.Id">
            <p
              :class="
                message.ComplaintResponseType === 'Guardian' ? 'right' : 'left'
              "
            >
              <span>{{ message.Response }}</span>
            </p>
          </div>
        </div>
        <form @submit.prevent="sendMessage">
          <input type="text" v-model="newMessage" />
          <button type="submit">Send</button>
        </form>
      </div>
    </div> -->
  </section>
</template>
<script>
import axios from "axios";
export default {
  name: "tet",
  data() {
    return {
      myData: [],
      messages: [],
      newMessage: "",
    };
  },
  mounted() {
    this.getResults();
  },
  methods: {
    mesgDisplay(resData, resDes) {
      const NewTitle = resData;
      const NewDesc = resDes;
      this.$swal.fire({
        icon: "info",
        title: NewTitle,
        text: NewDesc,
        showCancelButton: false,
      });
    },

    async getResults() {
      const token = localStorage.getItem("token");
      const studentid = this.$route.params.id;
      try {
        var model = {
          UserIdentity: token,
          StudentId: studentid,
        };
        const response = await axios.post(
          `https://apiesm.cyberasol.com/api/Mobile/ComplaintList`,
          model
        );
        this.myData = response.data.CMS_Mobile_Complaints;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style>

.swal2-title {
  color: #0f75bb !important;
  margin-bottom: 10px !important;
}
.add__btn a{
  text-decoration: none;
  display: inline-block;
  background: #27AE61;
  padding: 10px 20px;
  color: #fff;
  font-weight: 400;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
}
.add__btn a:hover {
  background:rgba(39, 174, 97, 0.9);
  color: #fff;
}
</style>
