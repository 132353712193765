<template>
    <section class="login__bg__wrapper">
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-lg-8">
                    <SignUpForm/>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import SignUpForm from "../components/SignUpForm.vue"
export default {
  name: "LoginView",
  components:{
    SignUpForm
  }
};
</script>