<template>
    <section class="login__bg__wrapper">
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-lg-7">
                    <LoginForm/>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import LoginForm from "../components/LoginForm.vue";
export default {
  name: "LoginView",
  components:{
    LoginForm
  }
};
</script>

