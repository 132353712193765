<template>
  <nav class="navbar bg-light">
    <div class="container align-items-center">
      <a class="navbar-brand" href="#">{{ this.$route.meta.title }}</a>
      <div class="d-flex align-items-center">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
        >
          <span class="navbar-toggler-icon"></span>
          <span class="menu__lable ps-1">MENU</span>
        </button>
        <div>
          <div class="dropdown">
            <button
              class="btn btn-secondary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="true"
            >
              <div v-if="!loading">
                <img
                  v-if="imgPath != null"
                  :src="`${imgPath}`"
                  alt="Avtar-profile"
                  class="img-fluid avatar__profile"
                />
                <img
                  v-else
                  src="../assets/AvatarDemo.png"
                  alt="Avtar-profile"
                  class="img-fluid avatar__profile"
                />
              </div>
              <div v-if="loading">
                <div class="spinner-border text-warning" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </button>
            <ul class="dropdown-menu">
              <li>
                <div class="profile__info__wrapper">
                  <h6 class="mb-1 mt-1">{{ studentName }}</h6>
                  <p class="mb-0">{{ className }}</p>
                  <p class="mb-0">{{ schoolName }}</p>
                </div>
              </li>
              <li>
                <router-link class="dropdown-item" @click="logout()" to=""
                  >Logout</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
            DASHBOARD MENU
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li class="nav-item" v-if="!nullData">
              <router-link
                class="nav-link nav-img"
                :to="{ name: 'paymentHistory' }"
              >
                <img
                  src="../assets/dashboard-icons/history.png"
                  alt="history"
                  class="me-3"
                />Payment History
              </router-link>
            </li>
            <li class="nav-item" v-if="!nullData">
              <router-link
                class="nav-link nav-img"
                :to="{ name: 'attendanceHistory' }"
              >
                <img
                  src="../assets/dashboard-icons/authentication.png"
                  alt="attendance"
                  class="me-3"
                />Attendance
              </router-link>
            </li>
            <li class="nav-item" v-if="!nullData">
              <router-link
                class="nav-link nav-img"
                :to="{ name: 'results' }"
              >
                <img
                  src="../assets/dashboard-icons/evaluation.png"
                  alt="results"
                  class="me-3"
                />Results
              </router-link>
            </li>
            <li class="nav-item" v-if="!nullData">
              <router-link
                class="nav-link nav-img"
                :to="{ name: 'smsAlert' }"
              >
                <img
                  src="../assets/dashboard-icons/report.png"
                  alt="alert-sms"
                  class="me-3"
                />Alert & SMS
              </router-link>
            </li>
            <li class="nav-item" v-if="!nullData">
              <router-link
                class="nav-link nav-img"
                :to="{ name: 'notification' }"
              >
                <img
                  src="../assets/dashboard-icons/bell.png"
                  alt="notifications"
                  class="me-3"
                />Notifications
              </router-link>
            </li>
            <li class="nav-item" v-if="!nullData">
              <router-link
                class="nav-link nav-img"
                :to="{ name: 'FeeBalance' }"
              >
                <img
                  src="../assets/dashboard-icons/money-report.png"
                  alt="fee-balance"
                  class="me-3"
                />Fee Balance
              </router-link>
            </li>
            <li class="nav-item" v-if="!nullData">
              <router-link
                class="nav-link nav-img"
                :to="{ name: 'EventCalendar' }"
              >
                <img
                  src="../assets/dashboard-icons/schedule.png"
                  alt="event-calendar"
                  class="me-3"
                />Event & Calendar
              </router-link>
            </li>
            <li class="nav-item" v-if="!nullData">
              <router-link
                class="nav-link nav-img"
                :to="{ name: 'complaints' }"
              >
                <img
                  src="../assets/dashboard-icons/complaint.png"
                  alt="complaint"
                  class="me-3"
                />Complaint
              </router-link>
            </li>
            <li class="nav-item" v-if="!nullData">
              <router-link
                class="nav-link nav-img"
                :to="{ name: 'diary' }"
              >
                <img
                  src="../assets/dashboard-icons/diary.png"
                  alt="diary"
                  class="me-3"
                />Diary
              </router-link>
            </li>
            <li class="nav-item" >
              <router-link class="nav-link nav-img" @click="logout()" to="">
                <img
                  src="../assets/dashboard-icons/power-off.png"
                  alt="logout"
                  class="me-3"
                />Logout
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Headernav",
  props: ["studentName", "schoolName", "className", "imgPath", "loading","nullData"],
  data() {
    return {
      pageTitle: "",
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("myItems");
      this.$router.push("/login");
    },
  },
};
</script>
