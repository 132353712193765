<template>
  <div class="col-lg-6 col-md-6 mb-3">
    <div class="single__content__wrapper">
      <div class="row">
        <div class="col-12 col-lg-4 col-md-12">
          <div class="single__profile__avatar">
            <div class="profile__avatar">
              <img
                v-if="imgPath != null"
                :src="`${imgPath}`"
                alt="profile-1"
                class="img-fluid"
              />
              <img
                v-else
                src="../assets/AvatarDemo.png"
                alt="profile-1"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-8 col-md-12">
          <div class="profile__content__wrapper">
            <div class="profile__content__list">
              <ul>
                <li>
                  <div class="row">
                    <div class="col-5 col-lg-4">
                      <div class="inner__title__label">
                        <label>Name :</label>
                      </div>
                    </div>
                    <div class="col-7 col-lg-8 ps-0">
                      <div class="inner__title__desc">
                        <label>{{ name }}</label>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="col-5 col-lg-4">
                      <div class="inner__title__label">
                        <label>S/D O :</label>
                      </div>
                    </div>
                    <div class="col-7 col-lg-8 ps-0">
                      <div class="inner__title__desc">
                        <label>{{ fatherName }}</label>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="col-5 col-lg-4">
                      <div class="inner__title__label">
                        <label>StudentID :</label>
                      </div>
                    </div>
                    <div class="col-7 col-lg-8 ps-0">
                      <div class="inner__title__desc">
                        <label>{{ StudentID }}</label>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="col-5 col-lg-4">
                      <div class="inner__title__label">
                        <label>Adm/Reg# :</label>
                      </div>
                    </div>
                    <div class="col-7 col-lg-8 ps-0">
                      <div class="inner__title__desc">
                        <label>{{ regNumber }}</label>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="col-5 col-lg-4">
                      <div class="inner__title__label">
                        <label>Class :</label>
                      </div>
                    </div>
                    <div class="col-7 col-lg-8 ps-0">
                      <div class="inner__title__desc">
                        <label>{{ className }}</label>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="single__profile__detail_btn text-center">
            <router-link :to="{name:'dashboard', params: { id: StudentID }}" class="custom__btn">View Details</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "studentList",
  props: {
    StudentID: {
      type: Number,
    },
    name: {
      type: String,
    },
    fatherName: {
      type: String,
    },
    regNumber: {
      type: String,
    },
    className: {
      type: String,
    },
    imgPath: {
      type: String,
    },
  },
  data() {
    return {
      myData: [],
      loading: false,
    };
  },
};
</script>
