<template>
  <section lang="html">
    <div class="row" v-if="!ifNoData">
      <div class="col-lg-12 mb-3" v-for="(item, index) in myData" :key="index">
        <div class="single__notification__main__wrapper">
          <div class="row">
            <div class="col-lg-8">
              <div class="notification__content__wrapper">
                <h3 class="mb-1">{{ item.NotificationTitle }}</h3>
                <p class="mb-0">{{ item.NotificationText }}</p>
              </div>
            </div>
            <div class="col-lg-4">
              <div
                class="notification__media__wrapper"
                v-for="(attach, index) in item.Files"
                :key="index"
              >
                <label class="d-block mb-1">Attachments</label>
                <a :href="`${attach.FileURL}`" target="_blank">{{
                  attach.FileName
                }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="ifNoData">
      <div class="col-lg-12">
        <div class="no__data_wrapper mt-4">
          <h2 class="mb-0">oops..! No Data Found</h2>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "MisconductView",
  data() {
    return {
      myData: [],
      NotificationTypeId: 5,
      ifNoData: false,
    };
  },
  created() {
    this.getResult();
  },
  methods: {
    async getResult() {
      const token = localStorage.getItem("token");
      const studentid = this.$route.params.id;
      try {
        var model = {
          UserIdentity: token,
          StudentId: studentid,
          NotificationTypeId: this.NotificationTypeId,
        };
        const response = await axios.post(
          `https://apiesm.cyberasol.com/api/Mobile/MobileGetStudentNotification`,
          model
        );
        this.myData = response.data;
        this.ifNoData = false;
        if (this.myData.length == 0) {
          this.ifNoData = true;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
